<template>
  <div id="companies-table">
    <vs-table
      :data="data"
      :max-items="maxItems"
      search
      pagination
      sorted
      stripe
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="header">
        <vs-button
          color="primary"
          type="flat"
          class="mt-4 mb-4 float-right"
          @click="openFilters"
          icon="icon-filter"
          icon-pack="feather"
        >
          {{ lang.general.filters[languageSelected] }}
        </vs-button>
      </template>
      <template slot="thead">
        <vs-th sort-key="name">
          {{ lang.companies.list.table.header.name[languageSelected] }}
        </vs-th>
        <vs-th sort-key="_createdAt">
          {{ lang.companies.list.table.header.created[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.companies.list.table.header.status[languageSelected] }}
        </vs-th>
        <vs-th sort-key="enabled">
          {{ lang.companies.list.table.header.enableDisable[languageSelected] }}
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.name">{{ tr.name }}</vs-td>
          <vs-td :data="tr._createdAt">{{ tr._createdAt | date(true) }}</vs-td>
          <vs-td :data="tr.enabled">
            {{
              lang.companies.list.table.body.statuses[`${tr.enabled}`][
                languageSelected
              ]
            }}
          </vs-td>
          <vs-td>
            <vx-slider
              color="success"
              @change="confirmStatus(tr)"
              v-model="tr.enabled"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'CompaniesTable',
  props: {
    data: Array,
    maxItems: {
      type: Number,
      default: 10
    },
    onOpenFilters: Function
  },
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    ...mapActions('companies', ['updateCompany']),
    ...mapMutations('companies', ['TOGGLE_COMPANY_STATUS']),
    confirmStatus(tr) {
      const newStatus = tr.enabled
        ? this.lang.companies.list.table.actions.statuses.enable[
            this.languageSelected
          ]
        : this.lang.companies.list.table.actions.statuses.disable[
            this.languageSelected
          ]

      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.companies.list.table.actions.updateStatus.title[
          this.languageSelected
        ],
        text: this.lang.companies.list.table.actions.updateStatus.text[
          this.languageSelected
        ]
          .replace('XXX', newStatus)
          .replace('YYY', tr.name),
        acceptText: this.lang.general.continue[this.languageSelected],
        cancelText: this.lang.general.cancel[this.languageSelected],
        accept: () => this.saveNewStatus(tr),
        cancel: () => this.revertNewStatus(tr)
      })
    },
    async saveNewStatus(tr) {
      this.$vs.loading()
      try {
        await this.updateCompany({
          companyId: tr._id,
          payload: {
            enabled: tr.enabled
          }
        })
        this.$vs.loading.close()
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.companies.messages.updateStatus.error[
            this.languageSelected
          ],
          color: 'danger',
          position: 'bottom-right'
        })
        this.revertNewStatus(tr)
        this.$vs.loading.close()
      }
    },
    revertNewStatus(tr) {
      this.TOGGLE_COMPANY_STATUS(tr._id)
    },
    openFilters() {
      if (this.onOpenFilters) {
        this.onOpenFilters()
      }
    }
  }
}
</script>

<style lang="scss">
#companies-table {
  .vs-table--header {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
</style>
